<template>
  <div v-if="data.articles.length" class="article-card-carusel">
    <h2 class="article-card-carusel__title">Aktuelt</h2>

    <Carousel
      :per-page="1"
      :scroll-per-page="false"
      :navigation-enabled="true"
      :pagination-enabled="false"
      :per-page-custom="[
        [768, 2],
        [992, 3],
        [1400, 4],
      ]"
      :navigation-next-label="nextLabel"
      :navigation-prev-label="prevLabel"
      :mouse-drag="false"
      :touch-drag="true"
      :space-padding="20"
    >
      <Slide v-for="(item, index) in data.articles" :key="index">
        <a :href="`${data.path}/${item.slug}`" class="article-card-carusel__link">
          <div
            :style="{ backgroundImage: 'url(' + item.media.cover[0].url + ')' }"
            class="article-card-carusel__image"
          ></div>
          <div v-html="item.title" class="article-card-carusel__text"></div> </a
      ></Slide>
    </Carousel>
  </div>
</template>

<script>
import { Slide, Carousel } from 'vue-carousel'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        articles: [],
        path: '',
      }),
    },
  },

  data() {
    return {
      nextLabel: "<img src='https://assets.kvass.no/63e607c908485954632a3095' />",
      prevLabel: "<img src='https://assets.kvass.no/63e607ccf370c554574eed90' />",
    }
  },

  components: {
    Slide,
    Carousel,
  },

  methods: {},
}
</script>

<style lang="scss">
.article-card-carusel {
  .VueCarousel-inner {
  }

  .VueCarousel:hover {
    .VueCarousel-navigation {
      display: initial;
    }
  }
  .VueCarousel-pagination,
  .VueCarousel-navigation--disabled,
  .VueCarousel-navigation {
    display: none;
  }

  .VueCarousel-navigation-button {
    color: white;
  }

  .VueCarousel-navigation-next,
  .VueCarousel-navigation-prev {
    top: 0;
    bottom: 0;
    margin: 0 !important;
    padding: 10px;
    transform: none;
    background: hsla(0, 0%, 100%, 0.3);
    width: 50px;

    &:focus {
      outline: none;
    }
  }

  margin: 0 auto;

  padding-bottom: 20px;
  line-height: 1.2;

  position: relative;
  transition: visibility 0.5s, opacity 0.5s;
  &:hover {
    .article-card-carusel__button {
      opacity: 1;
      &:disabled {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  &__title {
    color: $beige !important;
    padding: 3.5rem 1.5rem;
    max-width: 71.25rem;
    margin: 0rem auto;
    width: 100%;
    font-weight: normal;
    font-size: 2rem;

    @include respond-below('desktop') {
      max-width: 60rem;
    }

    @include respond-below('tablet') {
      max-width: 45rem;
    }

    @include respond-below('phone') {
      max-width: 30rem;
      font-size: 1.6rem;
    }

    @include respond-below('tiny') {
      font-size: 1.4rem;
    }
  }

  &__button {
    position: absolute;
    width: 50px;
    padding: 0;
    background: none;
    background: hsla(0, 0%, 100%, 0.4);
    border: 0;
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    transition: opacity 0.5s;
    margin: auto;
    top: 20px;
    bottom: 35px;
    // padding wrapper 20px 0 35px;
    transition: visibility 0.5s, opacity 0.5s;
    cursor: pointer;
    &:hover {
    }

    &-prev {
      left: 0;
    }
    &-next {
      right: 0;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    --card-gap: 1.25rem;
    overflow: hidden;
    padding: 20px 0 35px;
    position: relative;

    gap: var(--card-gap);
  }

  &__slide {
    // flex-grow: 1;
    // flex-basis: 0;
    // min-width: 0;
  }

  &__link {
    margin-right: 1rem;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3);

    --min-height: 194px;

    @include respond-below('desktop') {
      --min-height: 168px;
    }

    @include respond-below('tablet') {
      --min-height: 184px;
    }
    @include respond-below('phone') {
      --min-height: 152px;
    }

    display: flex;
    flex-direction: column;
    --card-spacing: 2rem;
    white-space: nowrap;

    color: rgb(254, 234, 221);
    background-color: rgb(69, 83, 96);
    text-decoration: none;
    transition: transform 0.3s, box-shadow 0.3s;

    &:hover {
      text-decoration: none;
      transform: scale(1.04);
    }
  }
  &__image {
    min-height: var(--min-height);

    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
  }
  &__text {
    width: 300px;

    @include respond-above('desktop') {
      width: 350px;
    }

    padding: 1rem 20px;
    margin: 0;
    height: 4rem;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1rem;

    font-weight: 400;
  }
}
</style>
