import { TransformTag } from '@/utils'

export default function() {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.slug', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }

  const post = (this.$path('posts', this.item) || []).find(
    post => this.$path('slug', post) === this.$path('_props.slug', this),
  )

  const postCustomFieldsConfig = this.$path('customFieldsConfig.0.source', post)

  const mainPost = (this.$path('posts', this.item) || []).find(
    post =>
      this.$path('customFieldsConfig.0.source', post) === postCustomFieldsConfig + '-applicable',
  )

  const articles = (this.$path('posts', this.item) || [])
    .filter(post => this.$path('customFieldsConfig.0.source', post) === 'article')
    .filter(article => {
      const type = this.$path('customFields.site-settings.type', article)
      if (!type) return false
      return type.includes(postCustomFieldsConfig)
    })
    .sort(
      (a, b) =>
        this.$path('customFields.site-settings.order', a) -
        this.$path('customFields.site-settings.order', b),
    )

  const getArticleUrlPath = () => {
    if (!post || !mainPost) return

    return `/${post.slug}/${mainPost.slug}`
  }

  return [
    {
      condition: get('media.cover.length'),
      component: 'Slider',
      data: get('media.cover'),
      props: {
        class: 'hero',
        aspectRatio: '16/6.5',
        options: {
          manualNavigation: false,
        },
      },
      blocks: {
        default: [
          {
            component: 'Section',
            props: {
              class: 'hero__overlay',
              width: 'full',
              style: 'padding: 0',
            },
            blocks: {
              default: {
                component: 'Text',
                props: {
                  class: 'hero__content',
                },
                data: {
                  content: TransformTag(get('content')),
                },
              },
            },
          },
        ],
      },
    },
    ...(get('customFields.additional-sections') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          class: 'additional-sections-first',
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          showResponsivePadding: true,
        },
      }
    }),
    {
      condition: () => {
        return (get('customFields.icons') || []).filter(i => {
          if (!i) return false
          return i.content || i.icon
        }).length
      },
      component: 'Section',

      props: {
        width: 'full',
        class: 'features',
        theme: get('customFields.icons-settings.theme') || 'none',
      },

      blocks: {
        default: [
          {
            component: 'Features',
            props: {
              columns: parseInt(get('customFields.icons-settings.columns')) || 4,
              iconBackgroundColor: get('customFields.icons-settings.show-icon-background-color')
                ? get('customFields.icons-settings.icon-background-color')
                : 'inherit',

              borderTheme: get('customFields.icons-settings.border-theme'),
              placement: get('customFields.icons-settings.placement'),
              padding: '0',
            },
            data: {
              content: get('customFields.icons'),
            },
            variant: get('customFields.icons-settings.variant') || 'none',
          },
        ],
      },
    },

    ...(get('customFields.additional-sections-1') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          class: 'additional-sections-features',
          padding: '2rem',
          showResponsivePadding: true,
        },
      }
    }),
    {
      condition: () => {
        return (get('customFields.additional-icons') || []).filter(i => {
          if (!i) return false
          return i.content || i.icon
        }).length
      },
      component: 'Section',

      props: {
        class: 'features additional-features',
        width: 'full',
        theme: get('customFields.additional-icons-settings.theme') || 'none',
      },

      blocks: {
        default: [
          {
            component: 'Features',
            props: {
              columns: parseInt(get('customFields.additional-icons-settings.columns')) || 4,
              iconBackgroundColor: get(
                'customFields.additional-icons-settings.show-icon-background-color',
              )
                ? get('customFields.icons-settings.icon-background-color')
                : 'inherit',

              borderTheme: get('customFields.additional-icons-settings.border-theme'),
              placement: get('customFields.additional-icons-settings.placement'),
              padding: '0',
            },
            data: {
              content: get('customFields.additional-icons'),
            },
            variant: get('customFields.additional-icons-settings.variant') || 'none',
          },
        ],
      },
    },
    ...(get('customFields.additional-sections-2') || []).map((i, index) => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          class: index === 0 ? 'additional-sections-padding-top' : '',
          gap: '0',
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',

          showResponsivePadding: true,
        },
      }
    }),

    {
      component: 'Section',
      condition: get('address.location.coordinates', 'Root.item'),
      props: {
        width: 'full',
        class: 'custom-map',
      },
      blocks: {
        default: {
          component: 'CustomMap',
          props: {
            style: 'min-height: 450px; max-width: 100%;',
            class: '',
            showControls: true,
            scrollZoom: false,
            multipleStyles: [
              {
                style: process.env.VUE_APP_MAPBOX_STYLE,
                name: 'Kart',
              },
              {
                style: process.env.VUE_APP_MAPBOX_STYLE_SATELLITE,
                name: 'Satellitt',
              },
            ],
          },
          data: {
            coordinates: get('address.location.coordinates', 'Root.item'),
          },
        },
      },
    },

    {
      component: 'Section',
      condition: get('address.location.coordinates', 'Root.item'),
      props: {
        width: 'small',
        class: 'travel-time',
      },
      blocks: {
        default: [
          {
            component: 'TravelCalculator',
            props: {
              icons: {
                'driving-traffic': ['far', 'hashtag'],
                cycling: ['fal', 'bicycle'],
              },
              buttonOptions: {
                theme: 'secondary',
              },
            },
            data: {
              coordinates: get('address.location.coordinates', 'Root.item'),
            },
            blocks: {
              default: {
                component: 'Text',
                condition: get('customFields.travel-time-content'),
                props: {
                  class: 'travel-time__content',
                },
                data: {
                  content: get('customFields.travel-time-content'),
                },
              },
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        theme: 'primary',
        style: 'padding: 0',
        width: 'full',
      },
      blocks: {
        default: {
          component: 'ArticleCardCarusel',
          props: {},
          data: {
            articles: articles,
            path: getArticleUrlPath(),
          },
        },
      },
    },
    {
      component: 'Banner',
      condition: get('customFields.contact-cta.content', 'Root.item'),
      props: {
        class: 'contact-cta-bottom ',
        style: 'padding-block: 3.5rem;',
      },
      data: {
        backgroundImage: get('customFields.contact-cta.image.url', 'Root.item'),
      },

      blocks: {
        default: {
          component: 'Text',
          data: {
            content: get('customFields.contact-cta.content', 'Root.item'),
          },
          props: {
            textAlign: 'center',
          },
        },
      },
    },
  ]
}
