<template>
  <Loader
    theme="overlay"
    :key="$path('title', post)"
    :value="promises.fetch"
    class="post-page"
    :class="{ 'page-has-no-cover': !$path('media.cover.length', post) }"
  >
    <Blocks v-if="item.posts" :blocks="config" :customComponents="customComponents" />
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'

// pagebuilder
import Config from '@/config/posts'
import { Blocks } from '@kvass/pagebuilder'

import CustomMap from '@/components/CustomMap.vue'
import ArticleCardCarusel from '@/components/ArticleCardCarusel.vue'
import TravelCalculator from '@kvass/travel-calculator'

import { animate, inView, stagger } from 'motion'

export default {
  props: {
    slug: {
      type: String,
      default: 'post',
    },
  },
  computed: {
    ...mapState('Root', ['promises', 'item']),
    config() {
      const sources = this.post.customFieldsConfig?.map(schema => schema.source) || ['subpage']
      return Config[sources[0]].call(this)
    },
    post() {
      if (!this.item.posts) return {}

      const post = this.item.posts.find(p => p.slug === this.slug)

      // redirect
      if (!post) {
        this.$router.push({ name: 'Project' })
      }

      return post
    },

    customComponents() {
      return { CustomMap, ArticleCardCarusel, TravelCalculator }
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
    async initAnimation() {
      inView('.hero', () => {
        animate(
          '.hero',
          { opacity: 1, transform: 'none' },
          { duration: 0.4, delay: stagger(0.2) },
          { easing: 'linear' },
        )
      })
      inView('.hero__content', () => {
        animate('.hero__content', { opacity: 1, transform: 'none' }, { duration: 2, delay: 0.4 })
      })

      const images = document.querySelectorAll('.kpb-gallery__item')
      if (images.length) {
        animate(
          images,
          { opacity: 1, transform: 'none' },
          { duration: 0.4, delay: stagger(0.2) },
          { easing: 'linear' },
        )
      }

      const button = document.querySelectorAll('a[data-cta]')
      if (button.length) {
        animate(button, { opacity: 1, transform: 'none' }, { duration: 2, delay: 0.6 })
      }
    },
  },
  created() {
    this.fetch().then(() => this.$nextTick(() => this.initAnimation()))
  },

  metaInfo() {
    return {
      title: this.$path('title', this.post),
    }
  },

  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.post-page {
  background-color: var(--background-color);

  .hero {
    opacity: 0;
    position: relative;
    color: $beige;
    max-width: 100%;

    .kpb-slider__slider {
      min-height: calc(1335px * 0.4);
      max-width: 100%;

      @include respond-below('phone') {
        min-height: calc(844px * 0.4);
      }
    }

    &__content {
      background: radial-gradient(rgba(0, 0, 0, 0.5) 0, transparent 60%);

      opacity: 0;
      color: $beige;
      margin: 0 auto;
      padding: 2rem 1.5rem;
      width: 100%;
      max-width: 28.125rem;
      text-align: center;

      h1 {
        font-size: 2.3rem;
        margin-top: 1.5rem;
        margin-bottom: 0.75rem;
        line-height: 1.2;
        font-weight: normal;
        font-style: normal;

        @include respond-below('phone') {
          font-size: 1.84rem;
        }

        @include respond-below('tiny') {
          font-size: 1.61rem;
        }
      }

      p {
        margin-top: 0rem;
        margin-bottom: 1.5rem;
      }

      p:last-child {
        margin-bottom: 0;
        a {
          margin-right: 1rem;
          margin-bottom: 1rem;
        }
      }
    }

    &__overlay {
      background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(60%, rgba(0, 0, 0, 0.2)),
        to(transparent)
      );
      background-image: -o-linear-gradient(rgba(0, 0, 0, 0.2) 60%, transparent);
      background-image: linear-gradient(rgba(0, 0, 0, 0.2) 60%, transparent);

      z-index: 1;
      position: absolute;
      top: 0;
      right: initial;
      bottom: initial;
      left: 0;
      display: flex;
      width: 100%;
      height: 100%;

      .kpb-section__container {
        position: relative;
        align-self: flex-end;
        padding: 2rem 1.5rem;
        margin: 0 auto;
        max-width: 30rem;

        @media (min-width: 768px) {
          max-width: 45rem;
        }

        @media (min-width: 992px) {
          max-width: 60rem;
        }

        @media (min-width: 1200px) {
          max-width: 71.25rem;
        }
      }
    }
  }

  .travel-time {
    &__content {
      margin: 0 auto;
      max-width: 30rem;
    }
  }

  .features {
    .kpb-section__container {
      max-width: 60rem;
    }

    --section-padding-inline: 0;

    padding-block: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;

    @include respond-below('phone') {
      padding: 1.25rem 1.75rem;
    }
  }

  .custom-map {
    padding: 5% 15% 0;

    @include respond-below('tablet') {
      padding: 0;
      padding-top: 2rem;
    }
  }

  .article-card-carusel-section {
    @include respond-above('phone') {
      padding-bottom: 5%;
    }
  }

  .additional-sections-first {
    .kpb-images-text-section__content {
      @include respond-below('phone') {
        padding: 1.5rem;
      }
    }
  }

  .additional-sections-second {
    padding: 0;
    padding-bottom: 2rem;

    @include respond-below('phone') {
    }
  }

  .additional-sections-contact {
    padding-top: 3.28253% !important;
    padding-bottom: 5% !important;
  }

  .additional-sections-features {
    .kpb-images-text-section__content {
      @include respond-below('phone') {
        padding: 1.5rem;
      }
    }
    .kpb-text {
      h2 {
        margin-top: 1rem;
        margin-bottom: 0rem;
        @include respond-below('phone') {
          margin-top: 0rem;
        }
      }
      a {
        margin-bottom: 1rem;
        @include respond-below('phone') {
          margin-top: 0.5rem;
        }
      }
    }
  }

  .additional-sections-padding-top {
    padding-top: 5% !important;
  }

  .kpb-images-text-section {
    &__images {
    }

    &__content {
      &-size--small {
        max-width: 37.5rem;
      }

      .kpb-text {
        h2 {
          margin-bottom: 0.75rem;
          margin-top: 1.5rem;
          font-size: 2rem;
          text-align: center;
          font-weight: normal;

          @include respond-below('phone') {
            font-size: 1.6rem;
          }

          @include respond-below('tiny') {
            font-size: 1.4rem;
          }
        }
        p {
          margin-top: 0;
        }
      }
    }

    &-layout {
      &--horizontal-image-left {
        padding: 0;

        .kpb-section__container {
          max-width: 100%;
          padding-inline: 0;
        }

        .kpb-images-text-section__images {
          height: 100%;
          padding: 3vh 0px 3vh 30%;

          @media (max-width: 767px) {
            .kpb-gallery__item:first-child {
              padding-top: 0rem;
            }
          }

          @include respond-below('tablet') {
            padding: 0 !important;
          }
        }

        .kpb-images-text-section__content {
          padding: 3vh 30% 3vh 8% !important;
          @include respond-below('tablet') {
            padding: 0 !important;
          }

          .kpb-text {
            margin-left: auto;
            margin-right: auto;
            max-width: calc(28.125rem + 2rem);
            padding: 2rem 1.5rem;
            h2 {
              text-align: left;
            }
          }
        }
      }
      &--vertical-image-top {
        padding: 3vh 15%;
        @include respond-below('tablet') {
          padding: 0 !important;
        }
        .kpb-section__container {
          max-width: 100%;
          padding-inline: 0;
        }
      }
    }
  }
}
</style>
