import { TransformTag } from '@/utils'

export default function() {
  const get = (path, source = 'Root.item.posts') => {
    const slug = 'forbehold'

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }

  return [
    {
      component: 'Section',
      props: {
        theme: 'primary',

        width: 'full',
      },
    },
    {
      component: 'Section',
      props: {
        theme: 'white',
        style: 'padding: 1.5rem',
        width: 'small',
      },
      blocks: {
        default: {
          component: 'Text',
          props: {
            class: 'forbehold',
          },
          data: {
            content: get('content'),
          },
        },
      },
    },
    {
      component: 'Section',
      props: {
        theme: 'primary',
        style: 'padding-block: 5%',
        width: 'full',
      },
    },
  ]
}
